<template>
  <div class="op">
    <div class="op-1">
      <div class="page-container">
        <div class="page_title">炼油行业</div>
        <el-row :gutter="55">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="op-title">行业现状</div>
            <div class="border-line"></div>
            <div
              v-for="(item,i) in data.sl"
              :key="i"
            >
              <div class="op1-title"><span class="dot"></span><span class="title">{{item}}</span></div>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-image
              :src="data.img[0]"
              fit="contain"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="op-2 page-grey">
      <div class="page-container">
        <el-row :gutter="55">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-image
              :src="data.img[1]"
              fit="contain"
            ></el-image>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="op-title">面临挑战</div>
            <div class="border-line"></div>
            <div
              v-for="(item,i) in data.tz"
              :key="i"
            >
              <div class="op1-title"><span class="dot"></span><span :class="['title',{'red':i==0||i==5}]">{{item}}</span></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="op-3">
      <div class="page-container">
        <el-row :gutter="55">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="op-title">解决方案</div>
            <div class="border-line"></div>
            <div
              v-for="(item,i) in data.fun"
              :key="i"
            >
              <div class="op1-title"><span class="num">{{'0'+(i+1)+'.'}}</span><span class="title">{{item}}</span></div>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-image
              :src="data.img[2]"
              fit="contain"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      class="page6 page-grey"
      v-if="!$store.state.isPhone"
    >
      <div class="page-container">
        <div class="page_title">方案架构</div>
        <el-image
          fit="contain"
          :src="require('../../assets/proInfo/n-pi-img-4.png')"
          class="img"
        ></el-image>
      </div>
    </div>

    <div
      class="p-page6 page-grey"
      v-if="$store.state.isPhone"
    >
      <div class="page_title">方案架构</div>
      <el-image
        fit="contain"
        :src="require('../../assets/proInfo/n-pi-img-4.png')"
        class="p-img"
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoOne",
  props: ['data'],
  data() {
    return {

    }
  },
}
</script>

<style lang="scss">
.op {
  .op-1,
  .op-2,
  .op-3,
  .page6 {
    padding: 65px 20px;
  }
  .page_title {
    color: #2f3154;
    line-height: 45px;
    letter-spacing: 2px;
    text-align: center;
    font-size: 2em;
    margin-bottom: 45px;
  }
  .op-title {
    color: #323456;
    line-height: 33px;
    letter-spacing: 1px;
    font-size: 1.25em;
  }
  .border-line {
    width: 30px;
    height: 2px;
    background-color: #274cfb;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #666666;
    display: inline-block;
    margin: 0 8px;
  }
  .title {
    color: #666666;
    line-height: 28px;
    letter-spacing: 2px;
    font-size: 0.625em;
  }

  .red {
    color: #c20606;
    font-size: 1em;
  }

  .num {
    font-size: 1.2em;
    margin: 0 8px;
  }

  .page6 {
    .p-img {
      width: 94%;
      padding: 3%;
      height: 350px;
      margin-top: 35px;
    }
    .img {
      width: 100%;
      height: 450px;
      margin-top: 35px;
    }
  }

  .p-page6 {
    padding: 35px 20px;
    .page-title {
      margin-bottom: 25px;
    }
  }
}
</style>
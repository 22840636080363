<template>
  <div class="page_container">
    <div class="banner">
      <div class="page-container">
        <div
          class="back"
          @click="handleBack"
        ><i class="el-icon-arrow-left"></i>返回</div>
        <p class="title">{{title}}</p>
        <!-- <p
          class="desc"
          v-if="bol"
        >{{desc1}}</p> -->
      </div>
    </div>
    <div v-if="bol">
      <div
        class="page2 page-container"
        ref="page2"
        v-if="!$store.state.isPhone"
      >
        <div class="page-title">{{`什么是${title}?`}}</div>
        <p class="sub_title">{{desc}}</p>
        <div class="page-container">
          <el-row>
            <el-col
              v-for="item in list2"
              :key="item.title"
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <div class="content">
                <el-image
                  :src="item.icon"
                  fit="contain"
                  class="icon"
                ></el-image>
                <p class="title">{{item.title}}</p>
                <p class="desc">{{item.desc}}</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div
        class="p-page2"
        v-if="$store.state.isPhone"
      >
        <div class="page-title">{{`什么是${title}?`}}</div>
        <p class="sub_title">{{desc}}</p>
        <div
          v-for="(item,i) in list2"
          :key="item.step"
          :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i*0.5}s`"
        >
          <div class="item-top">
            <div>
              <p class="step">{{item.step}}</p>
              <p class="title">{{item.title}}</p>
            </div>
            <div>
              <el-image
                fit="contain"
                :src="item.icon"
                class="image"
              ></el-image>
            </div>
          </div>
          <div class="item-bottom">
            <p>{{item.desc}}</p>
          </div>
          <el-image
            fit="containe"
            :src="require('../../assets/industry/i-page2-bg.png')"
            class="p2_bg"
          ></el-image>
        </div>
      </div>

      <div
        class="page1  page-grey"
        v-if="!$store.state.isPhone"
      >
        <div class="page-container">
          <div
            class="page-title"
            :style="`margin-bottom: ${is_bol?'35px':0}`"
          >{{`${title}功能`}}</div>
          <p
            class="sub_title"
            v-show="!is_bol"
          >{{desc1}}</p>
          <div>
            <el-row :gutter="20">
              <el-col
                :xs="8"
                :sm="8"
                :md="8"
                :lg="4"
                :xl="4"
                v-for="(item,i) in data"
                :key="i"
              >
                <div class="fun">
                  {{item}}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div
        class="p-page1  page-grey"
        v-if="$store.state.isPhone"
      >
        <div class="page-container">
          <div
            class="page-title"
            :style="`margin-bottom: ${is_bol?'35px':0}`"
          >{{`${title}功能`}}</div>
          <p
            class="sub_title"
            v-show="!is_bol"
          >{{desc1}}</p>
          <div>
            <el-row :gutter="20">
              <el-col
                :xs="12"
                :sm="12"
                :md="12"
                :lg="4"
                :xl="4"
                v-for="(item,i) in data"
                :key="i"
              >
                <div class="fun">
                  {{item}}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div
        class="page5"
        :style="`${$store.state.isPhone&&'padding:25px 0 0 0;'}`"
      >
        <div class="page-container">
          <div class="page-title">产品核心优势</div>

          <div class="content">
            <div
              v-for="(item,i) in ad"
              :key="item"
              class="item"
              :style="`background:url(${imgList[i]}) no-repeat;background-size: cover;width:${page5Index==i?100/(ad.length+1)*2:100/(ad.length+1)}%`"
              @mouseenter="page5Index=i"
            >
              <p>{{item}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="page6 page-grey"
        v-if="!$store.state.isPhone"
      >
        <div class="page-container">
          <div class="page-title">方案架构</div>
          <el-image
            fit="contain"
            :src="require('../../assets/proInfo/pro-info2.png')"
            class="img"
          ></el-image>
        </div>
      </div>

      <div
        class="p-page6 page-grey"
        v-if="$store.state.isPhone"
      >
        <div class="page-title">方案架构</div>
        <el-image
          fit="contain"
          :src="require('../../assets/proInfo/pro-info2.png')"
          class="p-img"
        ></el-image>
      </div> -->

      <!-- <div
      class="page3  page-grey"
      id="in3"
      v-if="!$store.state.isPhone"
    >
      <h-title msg="典型案例"></h-title>
      <div class="page-container">
        <el-image
          fit="cover"
          :src="require('../../assets/industry/i-dot.png')"
          class="p3-bg"
        ></el-image>
        <el-carousel
          arrow="nerver"
          indicator-position="outside"
        >
          <el-carousel-item
            v-for="(item,i) in list3"
            :key="i"
          >
            <div class="list-item">
              <div class="list-item-left">
                <el-image
                  fit="cover"
                  :src="item.img"
                  class="image"
                ></el-image>
              </div>
              <div class="list-item-right">
                <div class="title">{{item.title}}</div>
                <p class="desc">{{item.desc}}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div
      class="p-page3"
      v-if="$store.state.isPhone"
    >
      <h-title msg="典型案例"></h-title>
      <el-card
        :body-style="{ padding: '0px' }"
        v-for="(item,i) in list3"
        :key="i"
        class="content"
      >
        <el-image
          fit="cover"
          :src="item.img"
          class="image"
        ></el-image>
        <div style="padding: 14px;">
          <div class="list-item">

            <div class="list-item-right">
              <div class="title">{{item.title}}</div>
              <p class="desc">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </el-card>

    </div> -->

    </div>

    <div v-if="title=='复杂精馏装置实时优化'">
      <po-one :data="listOne"></po-one>
    </div>

    <div v-if="title=='氢气系统实时优化'">
      <po-two :data="listTwo"></po-two>
    </div>

    <div v-if="title=='蒸汽动力系统实时优化'">
      <po-three :data="listThree"></po-three>
    </div>

    <div v-if="title=='炼油行业MOM系统套件'">
      <po-imoo
        :title='title'
        :desc='desc'
        :desc1='desc1'
        :wt='wt'
        :sc='sc'
        :imgList='imgList'
      ></po-imoo>
    </div>
  </div>
</template>

<script>
import PoOne from './po-1'
import PoTwo from './po-2'
import PoThree from './po-3'
import PoImoo from './sl-1'
export default {
  name: "detail_in",
  components: {
    PoOne,
    PoTwo,
    PoThree,
    PoImoo
  },
  data() {
    return {
      title: localStorage.getItem('CISNEWSINFO'),
      bol: true,
      desc1: '',
      desc: '',
      data: [],
      ad: [],
      wt: [],
      sc: [],
      yy: '',
      is_bol: false,
      page5Index: 0,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true
        }
      },
      list: [
        { ad: ['化工批次全流程管理：研发过程、配方、工艺指标、计划排程、工艺过程、需求收集、客户投诉，以及以批次为主线的质量追溯', '强大的数据分析及可视化展示，为高管提供生产管理驾驶舱，支持可视化生产调度例会', '灵活的功能定制，根据用户岗位职责分配功能应用'], desc1: '石油化工行业精准数字化把控', list: ['化工批次管理', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '质量管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '石油化工行业MOM系统套件', desc: '以化工产品批次管理为主线，重点对新产品研发、原料配比、工艺方案、生产排程、生产过程批次跟踪、客户需求反馈等化工生产全流程进行监控和分析，实现企业业务流程的优化和生产过程的精细化管理，指导企业合理配置资源、科学调度排产，节能降耗、挖潜增效，提升对市场变化和突发异常的应变能力，使企业生产运行处于“安稳长满优”状态。', bol: false },
        { ad: ['适应固态、液态原料和产品', '兼顾流程生产与半流程（间歇式反应）生产特点', '支持自定义开发，功能无限扩展', '集成皮带秤计量系统、智能盘煤系统等煤化工特有的物料计量系统', '皮带秤数据系数校正'], desc1: '快速实现设备信息化采集', list: ['化工批次管理', '配煤数据校正', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '煤化工行业MOM系统套件', desc: '以煤化工全产业链为背景，通过计划、调度、节能降耗、优化操作、装置绩效管理等手段实现优化生产、提高装置运行平稳率，改进物料平衡，提高产品转化率、降低物耗能耗，降低环境污染，获得经济效益；通过对生产运行结果的数据分析，发现制约生产及经济效益的瓶颈问题，使企业管理向集约型和精细化转，实现生产的安稳长满优的目标。', bol: false },
        //跨集团业务协同（管网公司+中海油气电集团）
        { ad: ['产供储销一体化运营', '跨集团业务协同', '精准的气量预测和管存计算模型', '承运、托运、客户气量交接业务在线协同', '基础管理精细化、生产运营智能化、产业协同一体化、集团管控科学化'], desc1: '快速实现设备信息化采集', list: ['客户管理', '合同管理', '船货计划', '气量计划', '气量调度', '气量监测', '库存监测', '管存计算', '气量平衡', '气量交接', '槽车计划', '能耗管理', '设备检维修', '异常管理', '绩效管理', '统计报表', '调度指挥', '生产成本管理', '移动应用'], title: 'LNG行业MOM系统套件', desc: '以LNG全产业链为视角，通过MOM套件应用，覆盖产、供、储、销各环节的生产调度及运营业务，实现数据采集自动化、过程运行可视化、生产管理数字化和智能化，为LNG接收站开展国际贸易、生产经营、保供调度等决策提供智能化的数据分析结果和数据辅助。', bol: false },
        { ad: ['针对中小企业产、销、研一体化生产经营管理', '模块间的业务协同，计划-物料-库存-锁定', '刀具全生命周期管理，杜绝刀具浪费', '在线Andon系统，及时获取全面的异常信息', '实时生产看板，生产进度一目了然', '订单实时向客户反馈，客户实时掌握生产进度'], desc1: '快速实现供、销数据智能可视化', list: ['营销管理', '计划管理', '生产管理', '物料管理', '质量管理', '异常管理', '设备管理', '工艺技术管理', '生产看板', 'Andon管理', '刀具管理', '异常管理'], title: '机加工行业MOM系统套件', desc: '以订单及时交付为核心，实现从生产订单到产品完工的全部生产活动的优化管理，运用及时、准确的采集信息，指导、启动、响应并记录管理工厂的生产活动，从而能够对条件的变化做出迅速的响应、提高工厂运作过程的透明度和执行效率，降低生产库存、提高订单交付及时率、合格率，提高客户满意度，提升企业品牌形象和核心竞争力。', bol: false },
        { ad: ['支持弹性生产', '强化生产一体化管控、感知和预测预警能力', '质量分析数据的共享', '强化供应链协同优化能力，原料采购、生产加工、销售出厂的端到端全流程协同，支撑生产经营决策，资源优化配置'], desc1: '通过操作优化企业高附加值产品收率', list: ['流程监控', '安环监控', '计划管理', '调度管理', '操作管理', '物料管理', '能源管理', '仓库管理', '配方管理', '批次管理', '质量管理', '生产统计', '移动应用'], title: 'PVC行业MOM系统套件', desc: '实现生产业务对计划、调度、工艺、执行、统计、分析全过程的闭环管理，实现对生产、能耗、计量、质量、安环、设备等领域的基本覆盖，充分发挥系统在企业生产运行管理中的核心作用；增强企业对生产现场的动态监控与生产指挥能力，提高生产运行效率和协同管理水平，为业务发展战略提供支撑。', bol: false },
        { ad: ['透明、可视化的计划排产', '多终端应用（平板、工位机、PC、手机、PDA）', '模具自动匹配，防止混用、错用', '模具全生命周期管理', '基于RFID的生产全过程追溯', '生产异常分级预警，实时提醒'], desc1: '通过操作优化企业高附加值产品收率', list: ['计划排产管理', '车间作业管理', '质量管理', '设备管理', '模具管理', '物流仓储管理', '生产看板', '生产预警', '能耗分析', '统计报表', '移动应用'], title: '注塑行业MOM系统套件', desc: '针对注塑行业多品种、小批量、甚至单件的生产模式，通过条形码、RFID、数据采集器及扫描枪等数据采集手段,实时采集生产现场的机台运行的状况、模具的状态、在制品、物料、订单及品质等的信息，实现车间生产现场的透明化的管理，满足生产过程的追溯和管控的需要，并能与ERP/MRPII等信息系统对接和交互，提升生产相关职能部门的信息共享和协同的效率,提高了企业的核心的竞争力。', bol: false },
        {
          title: '炼油行业MOM系统套件',
          desc: '炼油MOM系统以MES为核心实现生产业务内计划、调度、工艺、执行、统计、分析全过程的闭环管理，达成生产与能耗、计量、质量、安环、设备、仓储等领域的衔接，实现对生产过程中的工艺进行实时监控和预警、对物耗能耗进行全流程跟踪和分析、对产品质量进行全面管控、对生产绩效进行精准考核与评价。',
          desc1: '工厂全方位实时监控，进出厂、装置运行、储罐存储、工艺、能耗等、结合数据建模和大数据分析给企业各业务管理决策提供有效指导意见',
          sc: ['业务全面覆盖，应用成熟，完美契合炼油生产管理业务', '强大的数据分析及可视化展示，为高管提供生产管理驾驶舱，支持可视化生产调度例会', '支持自定义开发，功能无限扩展', '灵活的功能定制，根据用户岗位职责分配功能应用'],
          wt: [
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-1.png"), step: 'STEP1', title: '多年沉淀、匠心出品', desc: '深耕石油化工行业十余年，每一个功能都精准衔接业务流程，贴合用户使用场景，完美契合炼油生产管理业务。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP2', title: '微服务化', desc: '采用全新的微服务架构，借助灵活解耦的微服务群，缩短业务上线周期和保障业务运行高可靠。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP3', title: '跨平台支持', desc: '支持x86架构、Arm架构，可以快速构建在Windows、Linux、macOS上运行。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-1.png"), step: 'STEP4', title: '容器部署', desc: '可以将应用以集装箱的方式打包交付,，提高可重复性，使开发人员和运维人员的工作更容易' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP5', title: '多租户SaaS化', desc: '实现在多租户环境下共用相同的系统或程序组件，并确保各租户间数据的隔离性，支持大数据存储高并发访问。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP6', title: '支持多种数据库', desc: '支持多个数据库或多类型数据库，每个服务可以是不同数据库，支持SQLServer、Oracle、MySQL、MongoDB、PostgreSql等主流数据库。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP7', title: '强大灵活的权限控制', desc: '单点登录(SSO)，授权机制符合RBAC（基于角色的访问控制）， 权限能控制到菜单、按钮、数据，授权范围级等，以及多语言多文化运行环境。' },
            { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP8', title: '丰富的统计图表', desc: '支持单表和多表、各种图形展示，轻松实现企业数据化分析和决策运营管理。' },
          ],
          imgList: [require("../../assets/solution/imoo/s-Imoo-img-1.png"), require("../../assets/solution/imoo/s-Imoo-img-3.png"), require("../../assets/solution/imoo/s-Imoo-img-2.png"), require("../../assets/solution/imoo/s-Imoo-img-4.png")],
          banner: '../../assets/solution/imoo/s-Imoo-banner.png',
          bol: false
        },

        { ad: ['集群能力，支持横向无限扩展', '单节点支持50万采集点', '自动并发调度，实现负载均衡', '采集频率可达到1秒', 'OPC、Modbus、104等300多种内置协议', '跨平台（安卓、苹果、windows）、跨浏览器（IE、Firefox、360、Chrome等）', '支持国际主流实时数据库集成（PI、PHD）'], desc1: '基于微服务的新一代智慧MOM产品', list: ['实时数据采集', '数据压缩和存储', '数据缓存和断点续传', '工艺流程图监测', '工艺数据报警', '工艺数据回放', '工艺数据分析', '设备状态监测', '安全环保监测', '能源管网监测', '工艺台账', '统计报表'], title: '实时数据库软件', desc: '集成全厂DCS、SIS、ITCC、GDS、PLC、SCADA等控制系统，实时采集工艺、质量、液位、浓度等过程数据，通过工艺流程图实现生产过程的实时监控、工艺/安全报警、工艺数据分析等业务应用。', bol: true },
        { ad: ['全生命周期：设计、采购、安装调试、运行、故障、调拨、报废。', '支持与ERP无缝集成：检维修工单、费用', '支持与仓储管理集成：备品备件库存', '预留三维数字化接口：三维可视化呈现', '适用范围：炼油、石油化工、煤化工、LNG、PVC、氯碱化工、盐化工、机加工、注塑、零部件装配、电力'], desc1: '能快速上手的工艺操作系统', list: ['设备台账管理', '设备档案管理', '检维修计划管理', '检维修工单管理', '故障管理', '设备启停管理', '设备运行管理', '润滑管理', '密封管理', '计量器具管理', '备品备件管理', '统计报表与绩效分析'], title: '设备全生命周期管理软件', desc: '以设备档案为核心，记录资产全生命周期中涉及到的建设、成本、文档、巡检、消缺、维修、运行、安全、退役等信息，并通过审批流程和待办业务功能，实现企业业务流程化、闭环化、精细化管理的需求。', bol: true },
        { ad: ['大计量管理：体系、数据、仪表、器具、人员报表', '支持与ERP无缝集成：计量数据一键发送ERP，实现实时发货过账', '支持与地磅系统无缝集成：实现进出厂称重计量无人值守', '计量器具检定预警：有效防止漏检、过检，保证计量器具准确性', '适用范围：炼油、石油化工、煤化工、LNG、PVC、氯碱化工、盐化工'], desc1: '实现生产过程实时监控', list: ['计量体系管理', '计量点管理', '装置加工计量', '罐区收付计量', '能源产耗计量', '物料进出厂计量', '计量仪表监测', '计量器具管理', '计量人员管理', '计量统计报表'], title: '计量管理软件', desc: '针对进出厂、装置加工、能源产耗等，建立覆盖全厂的准确的计量和统计模型，对各个环节计量数据进行实时采集、比对和分析，及时发现进出厂、产耗环节中的异常损耗等，分析仪表异常、跑冒滴漏等可能原因，降低生产损耗，实现降本增效，提升企业市场竞争力。', bol: true },
        { ad: ['全过程：从原、辅料进厂、装置加工、产品储运到成品出厂', '全方位：原料进厂，工厂间互供，生产装置的投入产出，罐区（仓储）库存与收付动态，产品出厂', '全天候：24小时连续，跟踪每一个移动事件', '可视化：可视化物料平衡模型测算', '适用范围：炼油、石油化工、煤化工、LNG、PVC、氯碱化工、盐化工、机加工、注塑、零部件装配、电力'], desc1: '实现提升物资流转的执行效率', list: ['物料主数据管理', '装置计量模型', '进出厂计量点管理', '自动装车系统集成', '地磅称重系统集成', '仓储系统集成', '进出厂计量', '装置投入产出计量', '罐区计量', '三剂/辅料计量', '物料移动管理', '物料平衡管理', '平衡报表'], title: '物料管理软件', desc: '针对生产型企业仓储管理，能够有效地应对工厂复杂的物料进出业务，提升物资流转的执行效率，并为完整的批次追溯和MES制程控制打下基础。WMS能够与MES、ERP深度无缝整合。', bol: true },
        { ad: ['能源闭环管控：编制、审核、发布、跟踪、分析、对标', '支持水、电、汽、碳、氮等能源介质数据采集', '适用范围：炼油、石油化工、煤化工、LNG、PVC、氯碱化工、盐化工、机加工、注塑、零部件装配、电力'], desc1: '实现企业业务精细化管理', list: ['能源主数据管理', '能源计量点管理', '能耗设备管理', '能源计划管理', '临时用能管理', '能源数据采集', '能源数据审核', '能源数据平衡', '能耗对标管理', '能源产耗综合分析', '能耗监测平台集成'], title: '能源管理软件', desc: '集成企业能源系统数据采集、处理和分析、控制和调度、平衡预测和管理等功能，降低重要能源介质放散，提高能源介质的回收和梯级利用水平，实现多能源介质的协同平衡与优化利用的企业能源管控系统协同，使企业能源绩效得以持续改进和保持。', bol: true },
        { ad: ['全集成：储罐监测系统、铁路收发作业系统、公路收发作业系统、自动装车系统、火灾报警系统、可燃气体浓度监测系统、消防设施监测系统、周界报警系统、门禁一卡通系统、视频监控系统', '一张图：一张图展示油库所有报警信息，用户不必每个系统去查找报警源。', '适用范围：成品油销售公司、加油站、国储库、炼油厂库区、煤制油库区、LNG接收站、军队联保中心油库'], desc1: '实现降本增效提升企业市场竞争力', list: ['综合展示', '生产运行监测', '消防/安防监测', '报警管理', '设备运行状态监测', '管线运行状态监测', '储罐运行监测', '公路装车过程监测', '问题管理', '绩效考评', '收发油分析', '大数据分析'], title: '油库智能安全生产管控软件', desc: '以油库基本设备设施及作业自动化为基础，依托油库控制网络和信息网络一体化构建，集成油库的工艺、消防、安防、设备等的现场实时数据，借助专业的大数据建模和数据分析技术，对油库进行运行监控、安全预警、操作规范性评价和数据分析应用，实现对油库安全生产运行的监控和管理。', bol: true },
        { ad: ['生产绩效：工艺指标、设备指标、质量指标、能耗指标、物耗指标、计划指标、安环指标', '适用范围：炼油、石油化工、煤化工、LNG、PVC、氯碱化工、盐化工、机加工、注塑、零部件装配、电力'], desc1: '实现生产过程实时监控', list: ['指标设置', '公式设计', '数据集成', '指标计算', '指标考核', '指标分析', '指标改进',], title: '生产绩效管理软件', desc: '建立生产绩效考核体系，实现生产执行、设备、质量、安全、环保等生产相关绩效指标的设计、计算、发布、考核；涵盖包括公司级、车间级、装置级、岗位级等各级生产绩效管理，为实现班组操作绩效评价提供依据，通过考核促进生产管理的精细化提升。', bol: true },
        { ad: ['全面质量管理：从样品采样化验到实验室业务的全面管理', '广播提醒：来样化验任务语音播报，及时获取任务信息', '化验进度跟踪：实时跟踪样品化验进度', '临时加样：调度指令下达，化验室接收后采样', '适用范围：炼油、石油化工、煤化工、PVC、盐化工、化工新产品研究院、水泥、制药、食品'], desc1: '实现提升物资流转的执行效率', list: ['频次样品管理', '临时采样管理', '数据采集和录入', '样品审核', '数据发布', '检验过程跟踪', '质量数据分析', '留样管理', '人员管理', '试剂管理', '库存管理', '设备管理', '通知提醒', '投诉管理', '仪器集成', '数据修约', '检验标准', '广播提醒'], title: '质量管理软件', desc: '建立全面质量管理体系，从原料进厂、产品加工、包装入库、产品出厂的全流程，对质量进行把控；生产岗位能够实时获取样品检验进度、质量检验结果、质量变化趋势，及时指导生产调整；质检岗位能够及时接收检验指令，加强生产与质检的业务协同和数据共享。', bol: true },
        { ad: ['工艺操作实时监测报警：基于秒级频率，实时监测工艺参数变化，对超出阈值的参数进行声光报警', '操作规程指导：工艺发生偏差时，自动判断可能原因，并给出操作规程指导', '操作绩效实时测算：实时跟踪操作绩效，促进操作能力改进，提高工艺稳定性和产品收率', '适用范围：炼油、石油化工、煤化工、PVC、盐化工、水泥、制药、食品'], desc1: '实现企业业务精细化管理', list: ['工艺档案管理', '工艺变更管理', '工艺指标库', '工艺操作监控报警', '工艺操作规程', '操作日志', '工艺台账', '装置开停工管理', '工艺偏差统计', '操作平稳率', '装置自控率', '联锁投用率', '操作绩效分析'], title: '工艺操作管理软件', desc: '建立企业工艺资料库，实现企业操作规程、工艺标准、工艺卡片等工艺技术资料的集中电子化管理；通过对工艺操作的实时监测，及时发现工艺偏差报警，为操作人员提供工艺操作规程指导，消除工艺偏差；基于实时的平稳率、联锁投用率等操作绩效指标计算和分析，以考核促进操作技能提升，优化装置操作，实现降本增效。', bol: true },
        { ad: ['化工批次全过程管理：客户需求产品研发、原料进厂、产品生产、产品包装、产品出厂、产品销售、客户反馈', '适用范围：石油化工、煤化工、PVC、盐化工、水泥、制药、食品'], desc1: '实现降本增效提升企业市场竞争力', list: ['新产品研发管理', '配方管理', '工艺指标管理', '生产订单管理', '生产计划排程', '生产过程监测', '原料批次管理', '生产批次管理', '包装批次管理', '产品出厂管理', '客户投诉管理', '客户需求管理', '质量追溯管理'], title: '化工批次管理软件', desc: '针对固体化工、零部件等小批量、多批次、多品种切换、间歇式生产特点，将企业从产品研发、生产、到包装出厂、客户销售的全过程工艺和质量参数进行建模，实现对产品全过程信息的跟踪和问题追溯。', bol: true },
        { ad: ['高效快捷的全流程调度作业计划安排', '基于调合优化的滚动生产安排', '针对企业大修以及突发事件合理安排生产', '公用工程系统平衡测算', '全厂生产效益测算', '适用范围：炼油、石油化工、煤化工'], desc1: '实现降本增效提升企业市场竞争力', list: ['炼油生产排产', '化工生产排产', '原油调合生产排产', '成品油调合生产排产', '计划执行监控', '图形建模与模拟', '数据集成任务调度', '生产效益测算',], title: '调度排产优化软件', desc: '炼油生产排产优化： 炼油企业从原油入厂到装置生产的全流程作业调度与排产优化；涵盖原油接卸、原油管 输、原油调合、原油加工、装置生产安排、装置物料走向安排、组分 跟踪、系统平衡测算等。化工生产排产优化：化工生产综合调度的全流程优化排产，功能包含原料进厂、原料调合、装置生产、产品走向、组分跟踪、产品出厂等。装置 生产牌号加工切换安排，以及乙烯、丙烯、碳四、裂解汽油等中间产 品流向平衡优化建议方案。成品油调合排产优化： 支持多产品调合优化排产，为调合作业生成优化配方，支持线性、非线性、复杂和衍生属性调合，适用于汽油、柴油和燃料油等油品调合。', bol: true },
      ],

      imgList: [require("../../assets/proInfo/pro-info-img1.png"), require("../../assets/proInfo/pro-info-img2.png"), require("../../assets/proInfo/pro-info-img3.png"), require("../../assets/proInfo/pro-info-img4.png"), require("../../assets/proInfo/pro-info-img5.png"), require("../../assets/proInfo/pro-info-img1.png"), require("../../assets/proInfo/pro-info-img2.png"),],
      list2: [
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-1.png"), step: 'STEP1', title: '多年沉淀、匠心出品', desc: '深耕石油化工行业十余年，每一个功能都精准衔接业务流程，贴合用户使用场景，完美契合炼油生产管理业务。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP2', title: '微服务化', desc: '采用全新的微服务架构，借助灵活解耦的微服务群，缩短业务上线周期和保障业务运行高可靠。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP3', title: '跨平台支持', desc: '支持x86架构、Arm架构，可以快速构建在Windows、Linux、macOS上运行。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-1.png"), step: 'STEP4', title: '容器部署', desc: '可以将应用以集装箱的方式打包交付,，提高可重复性，使开发人员和运维人员的工作更容易' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP5', title: '多租户SaaS化', desc: '实现在多租户环境下共用相同的系统或程序组件，并确保各租户间数据的隔离性，支持大数据存储高并发访问。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP6', title: '支持多种数据库', desc: '支持多个数据库或多类型数据库，每个服务可以是不同数据库，支持SQLServer、Oracle、MySQL、MongoDB、PostgreSql等主流数据库。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-2.png"), step: 'STEP7', title: '强大灵活的权限控制', desc: '单点登录(SSO)，授权机制符合RBAC（基于角色的访问控制）， 权限能控制到菜单、按钮、数据，授权范围级等，以及多语言多文化运行环境。' },
        { icon: require("../../assets/solution/imoo/s-Imoo-p1-3.png"), step: 'STEP8', title: '丰富的统计图表', desc: '支持单表和多表、各种图形展示，轻松实现企业数据化分析和决策运营管理。' },
      ],
      list3: [
        { img: require("../../assets/industry/i-page3-1.png"), title: '无锡永凯达齿轮有限公司', desc: '公司成立于1987年，1996年开始研发发动机皮带驱动系统，座落于江苏省无锡惠山经济开发区钱桥配套区景盛路15号 ，总投资1.5亿元，属民营股份制有限公司。公司占地面积33339.2平方米(50亩)，建筑面积41987平方米。主要从事高精度齿轮及发动机皮带驱动系统的开发及制造。公司是上汽大众、一汽大众、上汽荣威、上汽名爵、上汽汇众、奇瑞、比亚迪、海马、江淮的配套厂家，配套产品为汽车发动机齿轮、新能源汽车齿轮和自动皮带张紧器等零件。公司为阿特拉斯、英格索兰、寿力机械、日本日立、美国豪顿、无锡压缩机、无锡泛亚、IHI寿力、柳州富达、印度艾格、美国开利、美国昆西等公司配套压缩机齿轮。公司为美国伊顿公司配套高压油泵齿轮。' },
        { img: require("../../assets/industry/i-page3-1.png"), title: '无锡永凯达齿轮有限公司', desc: '公司成立于1987年，1996年开始研发发动机皮带驱动系统，座落于江苏省无锡惠山经济开发区钱桥配套区景盛路15号 ，总投资1.5亿元，属民营股份制有限公司。公司占地面积33339.2平方米(50亩)，建筑面积41987平方米。主要从事高精度齿轮及发动机皮带驱动系统的开发及制造。公司是上汽大众、一汽大众、上汽荣威、上汽名爵、上汽汇众、奇瑞、比亚迪、海马、江淮的配套厂家，配套产品为汽车发动机齿轮、新能源汽车齿轮和自动皮带张紧器等零件。公司为阿特拉斯、英格索兰、寿力机械、日本日立、美国豪顿、无锡压缩机、无锡泛亚、IHI寿力、柳州富达、印度艾格、美国开利、美国昆西等公司配套压缩机齿轮。公司为美国伊顿公司配套高压油泵齿轮。' }
      ],

      listOne: {
        sl: [
          '某炼厂常减压装置(2016年11月进行实施)',
          '原油加工能力为~650万吨/年',
          '装置长期在超出设计负荷的40%情况下运行，常顶冷凝器、柴油分离区域、轻蜡油/中蜡油分离区域为主要瓶颈',
          '全年加工20多种原油，平均每三天切换加工原油',
          '产品质量控制精度要求严格，所有侧线产品质量均有APC控制',
          '项目目标：通过操作优化，改进产品分离精度，改善高附加值产品收率'
        ],
        tz: [
          '长期处理量超过设计加工能力40%',
          '多种原油:原油1、原油2、原油3、混合油1、混合油2....',
          '加工原油变化频繁',
          '现有航煤软仪表',
          '神经元网络模型',
          '现场软仪表精度偏低，基本处于失效的状态',
        ],
        fun: [
          '调整汽提蒸汽用量',
          '改善分离精度',
          '优化全塔温度分布',
          '调整加热炉出口温度'
        ],
        img: [require("../../assets/proInfo/n-pi-img-1.png"), require("../../assets/proInfo/n-pi-img-2.png"), require("../../assets/proInfo/n-pi-img-3.png")],
        title: '复杂精馏装置实时优化',
        desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化'
      },

      listTwo: {
        title: '氢气系统实时优化',
        desc: '氢系统集成优化将“氢气系统+燃料气系统”集成为一个整体，从全厂氢网络系统的氢气利用入手，以最小公用工程氢气用量为目标，综合考虑氢气流量、氢气纯度、有害杂质、压力限制等因素，采用氢夹点技术，分析氢气网络的瓶颈，提取对氢气网络流股匹配的顺序和原则，对氢气分配网络系统进行整体优化，实现氢气的梯级利用，从而达到系统节约用氢的目的。',
        wt: [
          { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '能效分析', desc: '获取企业用能数据，提供能效状况分析并为企业提供用能优化建议。' },
          { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '设备预测', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
          { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '实现信息的高效传递、优化处理过程，实时掌控生产数据。' },
        ],
        ad: [
          '氢网络详细模拟、氢夹点分析、氢网络优化',
          '氢气和轻烃回收集成技术',
          '氢气与燃料气系统的集成',
          '氢气系统在线优化技术'
        ]
      },

      listThree: {
        title: '蒸汽动力系统实时优化',
        desc: '建立包括动力站、管网、生产装置在内的蒸汽动力系统模型，定量模拟蒸汽动力系统操作状态，基于热夹点技术分析，找出政企动力系统操作瓶颈；结合能源市场与生产实际，设计出全厂蒸汽动力系统优化运行模型，对产汽和分配环节进行优化，减少蒸汽用量，降低公用工程负荷。',
        wt: [
          { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '能效分析', desc: '获取企业用能数据，提供能效状况分析并为企业提供用能优化建议。' },
          { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '设备预测', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
          { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '实现信息的高效传递、优化处理过程，实时掌控生产数据。' },
        ],
        ad: [
          '动力站+管网+装置的蒸汽动力系统模型',
          '蒸汽网络详细模拟',
          '目标分析和诊断、蒸汽动力系统在线优化技术',
          '蒸汽动力系统设计、改造和操作优化'
        ]
      }
      //   { list: [], title: '蒸汽动力系统实时优化', desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化' },
      //   { list: [], title: '氢气系统实时优化', desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化' },
      // ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.title = localStorage.getItem('CISNEWSINFO')
      this.bol = this.title != '复杂精馏装置实时优化' && this.title != '氢气系统实时优化' && this.title != '蒸汽动力系统实时优化' && this.title != '炼油行业MOM系统套件'
      if (this.bol) {
        this.desc = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0].desc
        this.desc1 = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0].desc1
        this.data = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0].list
        this.ad = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0].ad
        this.is_bol = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0].bol
      }
      if (this.title == '炼油行业MOM系统套件') {
        let { desc, desc1, wt, sc,  banner, yy } = this.list.filter(e => e.title == localStorage.getItem('CISNEWSINFO'))[0]
        this.desc = desc
        this.desc1 = desc1
        this.wt = wt
        this.sc = sc
        // this.imgList = imgList
        this.banner = banner
        this.yy = yy
      }
    },
    handleBack() {
      this.$router.push({ path: '/pro' })
    },
  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route': function () {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner {
    width: 100%;
    height: 450px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
    }
    .desc {
      width: 750px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner {
    width: 100%;
    height: 350px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
      padding-left: 20px;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      padding-left: 20px;
    }
    .desc {
      width: 320px;
      padding-left: 20px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
.page_container {
  background-color: #fff;
  .page-title {
    color: #2f3154;
    line-height: 45px;
    letter-spacing: 2px;
    font-size: 1.825em;
    text-align: center;
    font-weight: 500;
  }
  .sub_title {
    color: #666666;
    line-height: 28px;
    letter-spacing: 1px;
    font-size: 0.825em;
    text-align: center;
    padding: 15px 20px;
    margin-bottom: 25px;
  }
  .page2,
  .page1,
  .page5,
  .page6 {
    padding: 65px 0;
  }
  .p-page2,
  .p-page1 {
    padding: 25px 0;
    .page-title {
      color: #2f3154;
      line-height: 45px;
      letter-spacing: 2px;
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
    }
    .sub_title {
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      font-size: 0.825em;
      text-align: center;
      padding: 15px 20px;
      margin-bottom: 25px;
    }
  }

  .p-page1 {
    padding: 25px 20px;
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .page1 {
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }
  .p-page2 {
    .list-item-pro {
      margin: 20px;
      margin-top: 0;
      padding: 35px;
      box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
      position: relative;
      z-index: 1;
      background-color: #fff;
      position: relative;
      .p2_bg {
        width: 25px;
        position: absolute;
        right: -6px;
        bottom: -6px;
      }
      .item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .step {
          font-size: 0.625em;
          color: #11223f;
          line-height: 22px;
        }
        .image {
          width: 45px;
          height: 45px;
        }
        .title {
          font-size: 0.825em;
          font-weight: 500;
          margin-top: 15px;
        }
      }
      .item-bottom {
        font-size: 0.625em;
        color: #11223f;
        margin-top: 15px;
      }
    }
  }

  .page2 {
    padding-bottom: 85px;
    .page-container {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 200px;
        padding: 35px 35px;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        }
        .icon {
          width: 60px;
          height: 60px;
        }
        .title {
          color: #1e2046;
          line-height: 43px;
          font-size: 0.825em;
          font-weight: bold;
        }
        .desc {
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          font-size: 0.625em;
        }
      }
      .list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        .list-item-pro {
          width: 200px;
          padding: 35px;
          box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
          position: relative;
          z-index: 1;
          background-color: #fff;
          position: relative;
          .p2_bg {
            width: 25px;
            position: absolute;
            right: -6px;
            bottom: -6px;
          }
          .item-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .step {
              font-size: 0.625em;
              color: #11223f;
              line-height: 22px;
            }
            .image {
              width: 45px;
              height: 45px;
            }
            .title {
              font-size: 0.825em;
              font-weight: 500;
              margin-top: 15px;
            }
          }
          .item-bottom {
            font-size: 0.625em;
            color: #11223f;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .page3 {
    padding-bottom: 85px;
    .page-container {
      position: relative;
      .p3-bg {
        position: absolute;
        top: -50px;
        left: -50px;
        width: 250px;
      }
      .list-item {
        display: flex;
        flex-direction: row;
        padding: 30px;
        .list-item-left {
          margin-right: 30px;
          .image {
            width: 520px;
          }
        }
        .list-item-right {
          width: 520px;
          padding-bottom: 20px;
          .title {
            color: #00233e;
            font-size: 1.25em;
            line-height: 3em;
          }
          .desc {
            color: #666666;
            font-size: 0.825em;
            line-height: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 7;
            -moz-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }

  .p-page3 {
    .content {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      .title {
        font-size: 1.25em;
        line-height: 2em;
      }
      .desc {
        font-size: 0.625em;
        color: #909090;
      }
    }
  }

  .page5 {
    .content {
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      .item {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        color: #fff;
      }
    }
  }

  .page6 {
    .p-img {
      width: 94%;
      padding: 3%;
      height: 350px;
      margin-top: 35px;
    }
    .img {
      width: 100%;
      height: 450px;
      margin-top: 35px;
    }
  }

  .p-page6 {
    padding: 35px 20px;
    .page-title {
      margin-bottom: 25px;
    }
  }
}
</style>

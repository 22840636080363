<template>
  <div class="page_container">

    <div
      class="page2 page-container"
      ref="page2"
      v-if="!$store.state.isPhone"
    >
      <div class="page-title">{{`什么是${data.title}?`}}</div>
      <p class="sub_title">{{data.desc}}</p>
      <div class="page-container">
        <div class="list-item">
          <div
            v-for="(item,i) in data.wt"
            :key="item.step"
            :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i+1}s`"
          >
            <div class="item-top">
              <div>
                <p class="step">{{item.step}}</p>
                <p class="title">{{item.title}}</p>
              </div>
              <div>
                <el-image
                  fit="contain"
                  :src="item.icon"
                  class="image"
                ></el-image>
              </div>
            </div>
            <div class="item-bottom">
              <p>{{item.desc}}</p>
            </div>
            <el-image
              fit="containe"
              :src="require('../../assets/industry/i-page2-bg.png')"
              class="p2_bg"
            ></el-image>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-page2"
      v-if="$store.state.isPhone"
    >
      <div class="page-title">{{`什么是${data.title}?`}}</div>
      <p class="sub_title">{{data.desc}}</p>
      <div
        v-for="(item,i) in data.wt"
        :key="item.step"
        :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i}s`"
      >
        <div class="item-top">
          <div>
            <p class="step">{{item.step}}</p>
            <p class="title">{{item.title}}</p>
          </div>
          <div>
            <el-image
              fit="contain"
              :src="item.icon"
              class="image"
            ></el-image>
          </div>
        </div>
        <div class="item-bottom">
          <p>{{item.desc}}</p>
        </div>
        <el-image
          fit="containe"
          :src="require('../../assets/industry/i-page2-bg.png')"
          class="p2_bg"
        ></el-image>
      </div>
    </div>

    <div
      class="page6 page-grey"
      v-if="!$store.state.isPhone"
    >
      <div class="page-container">
        <div class="page-title">方案架构</div>
        <el-image
          fit="contain"
          :src="require('../../assets/proInfo/n-qq-img-1.png')"
          class="img"
        >
<!--          require('../../assets/proInfo/n-qq-img-1.png')-->
        </el-image>
      </div>
    </div>

    <div
      class="p-page6 page-grey"
      v-if="$store.state.isPhone"
    >
      <div class="page-title">方案架构</div>
      <el-image
        fit="contain"
        :src="require('../../assets/proInfo/n-qq-img-1.png')"
        class="p-img"
      ></el-image>
    </div>

    <div
      class="page5"
      :style="`${$store.state.isPhone&&'padding:25px 0 0 0;'}`"
    >
      <div class="page-container">
        <div class="page-title">产品核心优势</div>

        <div class="content">
          <div
            v-for="(item,i) in data.ad"
            :key="item"
            class="item"
            :style="`background:url(${imgList[i]}) no-repeat;background-size: cover;width:${page5Index==i?100/(data.ad.length+1)*2:100/(data.ad.length+1)}%`"
            @mouseenter="page5Index=i"
          >
            <p>{{item}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="page3  page-grey"
      id="in3"
      v-if="!$store.state.isPhone"
    >
      <h-title msg="典型案例"></h-title>
      <div class="page-container">
        <el-image
          fit="cover"
          :src="require('../../assets/industry/i-dot.png')"
          class="p3-bg"
        ></el-image>
        <el-carousel
          arrow="nerver"
          indicator-position="outside"
        >
          <el-carousel-item
            v-for="(item,i) in list3"
            :key="i"
          >
            <div class="list-item">
              <div class="list-item-left">
                <el-image
                  fit="cover"
                  :src="item.img"
                  class="image"
                ></el-image>
              </div>
              <div class="list-item-right">
                <div class="title">{{item.title}}</div>
                <p class="desc">{{item.desc}}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div
      class="p-page3"
      v-if="$store.state.isPhone"
    >
      <h-title msg="典型案例"></h-title>
      <el-card
        :body-style="{ padding: '0px' }"
        v-for="(item,i) in list3"
        :key="i"
        class="content"
      >
        <el-image
          fit="cover"
          :src="item.img"
          class="image"
        ></el-image>
        <div style="padding: 14px;">
          <div class="list-item">

            <div class="list-item-right">
              <div class="title">{{item.title}}</div>
              <p class="desc">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </el-card>

    </div> -->
  </div>
</template>

<script>
export default {
  name: "PoTwo",
  props: ['data'],
  data() {
    return {
      page5Index: 0,
      imgList: [require("../../assets/proInfo/pro-info-img1.png"), require("../../assets/proInfo/pro-info-img2.png"), require("../../assets/proInfo/pro-info-img3.png"), require("../../assets/proInfo/pro-info-img4.png"), require("../../assets/proInfo/pro-info-img5.png"), require("../../assets/proInfo/pro-info-img1.png"), require("../../assets/proInfo/pro-info-img2.png"),],
    }
  },
  mounted() {
  },
  methods: {
  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route': function () {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner {
    width: 100%;
    height: 450px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
    }
    .desc {
      width: 750px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner {
    width: 100%;
    height: 350px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
      padding-left: 20px;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      padding-left: 20px;
    }
    .desc {
      width: 320px;
      padding-left: 20px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
.page_container {
  background-color: #fff;
  .page-title {
    color: #2f3154;
    line-height: 45px;
    letter-spacing: 2px;
    font-size: 1.825em;
    text-align: center;
    font-weight: 500;
  }
  .sub_title {
    color: #666666;
    line-height: 28px;
    letter-spacing: 1px;
    font-size: 0.825em;
    text-align: center;
    padding: 15px 20px;
    margin-bottom: 25px;
  }
  .page2,
  .page1,
  .page5,
  .page6 {
    padding: 65px 0;
  }
  .p-page2,
  .p-page1 {
    padding: 25px 0;
    .page-title {
      color: #2f3154;
      line-height: 45px;
      letter-spacing: 2px;
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
    }
    .sub_title {
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      font-size: 0.825em;
      text-align: center;
      padding: 15px 20px;
      margin-bottom: 25px;
    }
  }

  .p-page1 {
    padding: 25px 20px;
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .page1 {
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }
  .p-page2 {
    .list-item-pro {
      margin: 20px;
      margin-top: 0;
      padding: 35px;
      box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
      position: relative;
      z-index: 1;
      background-color: #fff;
      position: relative;
      .p2_bg {
        width: 25px;
        position: absolute;
        right: -6px;
        bottom: -6px;
      }
      .item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .step {
          font-size: 0.625em;
          color: #11223f;
          line-height: 22px;
        }
        .image {
          width: 45px;
          height: 45px;
        }
        .title {
          font-size: 0.825em;
          font-weight: 500;
          margin-top: 15px;
        }
      }
      .item-bottom {
        font-size: 0.625em;
        color: #11223f;
        margin-top: 15px;
      }
    }
  }

  .page2 {
    padding-bottom: 85px;
    .page-container {
      .list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        .list-item-pro {
          width: 200px;
          padding: 35px;
          box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
          position: relative;
          z-index: 1;
          background-color: #fff;
          position: relative;
          .p2_bg {
            width: 25px;
            position: absolute;
            right: -6px;
            bottom: -6px;
          }
          .item-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .step {
              font-size: 0.625em;
              color: #11223f;
              line-height: 22px;
            }
            .image {
              width: 45px;
              height: 45px;
            }
            .title {
              font-size: 0.825em;
              font-weight: 500;
              margin-top: 15px;
            }
          }
          .item-bottom {
            font-size: 0.625em;
            color: #11223f;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .page3 {
    padding-bottom: 85px;
    .page-container {
      position: relative;
      .p3-bg {
        position: absolute;
        top: -50px;
        left: -50px;
        width: 250px;
      }
      .list-item {
        display: flex;
        flex-direction: row;
        padding: 30px;
        .list-item-left {
          margin-right: 30px;
          .image {
            width: 520px;
          }
        }
        .list-item-right {
          width: 520px;
          padding-bottom: 20px;
          .title {
            color: #00233e;
            font-size: 1.25em;
            line-height: 3em;
          }
          .desc {
            color: #666666;
            font-size: 0.825em;
            line-height: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 7;
            -moz-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }

  .p-page3 {
    .content {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      .title {
        font-size: 1.25em;
        line-height: 2em;
      }
      .desc {
        font-size: 0.625em;
        color: #909090;
      }
    }
  }

  .page5 {
    .content {
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      .item {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        color: #fff;
      }
    }
  }

  .page6 {
    .p-img {
      width: 94%;
      padding: 3%;
      height: 350px;
      margin-top: 35px;
    }
    .img {
      width: 100%;
      height: 450px;
      margin-top: 35px;
    }
  }

  .p-page6 {
    padding: 35px 20px;
    .page-title {
      margin-bottom: 25px;
    }
  }
}
</style>

<template>
  <div class="page_container">
    <!-- <div class="banner">
      <div class="page-container">
        <div
          class="back"
          @click="handleBack"
        ><i class="el-icon-arrow-left"></i>返回</div>
        <p class="title">{{title+'解决方案'}}</p>
      </div>
    </div> -->

    <div
      class="page2 page-container"
      ref="page2"
      v-if="!$store.state.isPhone"
    >
      <div class="page-title">{{`什么是${title}解决方案?`}}</div>
      <p class="sub_title">{{desc}}</p>
      <div class="page-container">
        <el-row>
          <el-col
            v-for="item in wt"
            :key="item.title"
            :xs="24"
            :sm="24"
            :md="6"
            :lg="6"
            :xl="6"
          >
            <div class="content">
              <el-image
                :src="item.icon"
                fit="contain"
                class="icon"
              ></el-image>
              <p class="title">{{item.title}}</p>
              <p class="desc">{{item.desc}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      class="p-page2"
      v-if="$store.state.isPhone"
    >
      <div class="page-title">{{`什么是${title}?`}}</div>
      <p class="sub_title">{{desc}}</p>
      <div
        v-for="(item,i) in wt"
        :key="item.step"
        :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i}s`"
      >
        <div class="item-top">
          <div>
            <p class="step">{{item.step}}</p>
            <p class="title">{{item.title}}</p>
          </div>
          <div>
            <el-image
              fit="contain"
              :src="item.icon"
              class="image"
            ></el-image>
          </div>
        </div>
        <div class="item-bottom">
          <p>{{item.desc}}</p>
        </div>
        <el-image
          fit="containe"
          :src="require('../../assets/industry/i-page2-bg.png')"
          class="p2_bg"
        ></el-image>
      </div>
    </div>

    <div class="page7" id="page7" :style="`background:url(${imgListT[0]}) no-repeat;background-size:cover`">
      <div style="">
      <div class="page-container">
        <div class="page-title">简洁的界面设计+全新的交互体验</div>
        <p class="sub_title">全新UI界面，集成各功能数据卡片，用户根据需要定制化数据展示界面</p>

        <div class="content">
          <el-row :gutter="55">
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12"
            >
            <div @mouseenter="handleMove(0)">
              <el-image
                class="img"
                :src="require('../../assets/solution/imoo/s-Imoo-p-1.png')"
                fit="cover"
              ></el-image>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <div  @mouseenter="handleMove(1)">
              <el-image
                class="img"
                :src="require('../../assets/solution/imoo/s-Imoo-p-2.png')"
                fit="cover"
                
              ></el-image>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      </div>
    </div>

    <div
      class="page6 page-grey"
      v-if="!$store.state.isPhone"
    >
      <div class="page-container">
        <div class="page-title">{{`${title}应用功能`}}</div>
        <p class="sub_title">{{desc1}}</p>
        <el-image
          fit="contain"
          :src="require('../../assets/solution/imoo/s-Imoo-yy.png')"
          class="img"
        ></el-image>
      </div>
    </div>

    <div
      class="p-page6 page-grey"
      v-if="$store.state.isPhone"
    >
      <div class="page-title">{{`${title}应用功能`}}</div>
      <p class="sub_title">{{desc1}}</p>
      <el-image
        fit="contain"
        :src="require('../../assets/solution/imoo/s-Imoo-yy.png')"
        class="p-img"
      ></el-image>
    </div>

    <div
      class="page5"
      :style="`${$store.state.isPhone&&'padding:25px 0 0 0;'}`"
    >
      <div class="page-container">
        <div class="page-title">应用场景</div>

        <div class="content">
          <div
            v-for="(item,i) in sc"
            :key="item"
            class="item"
            :style="`background:url(${imgList[i]}) no-repeat;background-size: cover;width:${page5Index==i?100/(sc.length+1)*2:100/(sc.length+1)}%`"
            @mouseenter="page5Index=i"
          >
            <p>{{item}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail_in",
  props: ['title', 'desc', 'desc1', 'wt', 'sc', 'imgList','banner'],
  data() {
    return {
      page5Index: 0,
      pageIndex: 0,
      imgListT:[require("../../assets/solution/imoo/s-Imoo-p-1.png"),require("../../assets/solution/imoo/s-Imoo-p-2.png")]
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-1)
    },
    handleMove(i){
      this.pageIndex = i

    }
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner {
    width: 100%;
    height: 450px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      margin-top: 20px;
    }
    .desc {
      width: 750px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }

  .page7 {
    background-size:cover;
    .content {
      width: 780px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 300px;
        height: 450px;
        margin: 0 auto;
      }
    }
    
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner {
    width: 100%;
    height: 350px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
      padding-left: 20px;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      padding-left: 20px;
      margin-top: 20px;
    }
    .desc {
      width: 320px;
      padding-left: 20px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }

  .page7 {
    background-size:cover;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      .img {
        width: 300px;
        height: 450px;
        display: block;
        margin: 0 auto;
      }
    }
    
  }
}
.page_container {
  background-color: #fff;
  .page-title {
    color: #2f3154;
    line-height: 45px;
    letter-spacing: 2px;
    font-size: 1.825em;
    text-align: center;
    font-weight: 500;
  }
  .sub_title {
    color: #666666;
    line-height: 28px;
    letter-spacing: 1px;
    font-size: 0.825em;
    text-align: center;
    padding: 15px 20px;
    margin-bottom: 25px;
  }
  .page2,
  .page1,
  .page5,
  .page6,
  .page7 {
    padding: 65px 0;
  }
  .p-page2,
  .p-page1 {
    padding: 25px 0;
    .page-title {
      color: #2f3154;
      line-height: 45px;
      letter-spacing: 2px;
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
    }
    .sub_title {
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      font-size: 0.825em;
      text-align: center;
      padding: 15px 20px;
      margin-bottom: 25px;
    }
  }

  .p-page1 {
    padding: 25px 20px;
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .page1 {
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }
  .p-page2 {
    .list-item-pro {
      margin: 20px;
      margin-top: 0;
      padding: 35px;
      box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
      position: relative;
      z-index: 1;
      background-color: #fff;
      position: relative;
      .p2_bg {
        width: 25px;
        position: absolute;
        right: -6px;
        bottom: -6px;
      }
      .item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .step {
          font-size: 0.625em;
          color: #11223f;
          line-height: 22px;
        }
        .image {
          width: 45px;
          height: 45px;
        }
        .title {
          font-size: 0.825em;
          font-weight: 500;
          margin-top: 15px;
        }
      }
      .item-bottom {
        font-size: 0.625em;
        color: #11223f;
        margin-top: 15px;
      }
    }
  }

  .page2 {
    padding-bottom: 85px;
    .page-container {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 200px;
        padding: 35px 35px;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        }
        .icon {
          width: 60px;
          height: 60px;
        }
        .title {
          color: #1e2046;
          line-height: 43px;
          font-size: 0.825em;
          font-weight: bold;
        }
        .desc {
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          font-size: 0.625em;
        }
      }
    }
  }

  .page3 {
    padding-bottom: 85px;
    .page-container {
      position: relative;
      .p3-bg {
        position: absolute;
        top: -50px;
        left: -50px;
        width: 250px;
      }
      .list-item {
        display: flex;
        flex-direction: row;
        padding: 30px;
        .list-item-left {
          margin-right: 30px;
          .image {
            width: 520px;
          }
        }
        .list-item-right {
          width: 520px;
          padding-bottom: 20px;
          .title {
            color: #00233e;
            font-size: 1.25em;
            line-height: 3em;
          }
          .desc {
            color: #666666;
            font-size: 0.825em;
            line-height: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 7;
            -moz-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }

  .p-page3 {
    .content {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      .title {
        font-size: 1.25em;
        line-height: 2em;
      }
      .desc {
        font-size: 0.625em;
        color: #909090;
      }
    }
  }

  .page5 {
    .content {
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      .item {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        color: #fff;
      }
    }
  }

  .page6 {
    .p-img {
      width: 94%;
      padding: 3%;
      height: 350px;
      margin-top: 35px;
    }
    .img {
      width: 100%;
      height: 450px;
      margin-top: 35px;
    }
  }

  .p-page6 {
    padding: 35px 20px;
    .page-title {
      margin-bottom: 25px;
    }
  }





}
</style>